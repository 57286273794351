<template>
  <page class="container">
    <v-header></v-header>
    <div class="scroll-container message">
      <van-tabs v-model="active" background="#fff" animated swipeable>
        <van-tab>
          <template #title>
            <span class="tab-title">全部消息</span>
          </template>
          <div class="tab-body">
            <div class="top-line">
              <div class="focus-tip" @click="$refs.gzhDialog.show()">想第一时间收到全部消息？</div>
              <div class="all-read">
                <a href="javascript: void (0)" v-if="systemMessage.length" @click="readAll">全部已读</a>
              </div>
            </div>
            <v-scroll text="无消息" src="no_message" height="calc(100vh - 78px - var(--header-height)" class="scroll-view"
                      :http="systemMessageApi" @success="loadedSystemMessage">
              <template v-for="i in systemMessage">
                <v-msg :detail="i" :t="i.messageType"></v-msg>
              </template>
            </v-scroll>
          </div>
        </van-tab>
        <van-tab>
          <template #title>
            <span class="tab-title">奖励消息</span>
          </template>

          <div class="tab-body">
            <div class="top-line">
              <div class="focus-tip" @click="$refs.gzhDialog.show()">想第一时间收到奖励消息？</div>
              <div class="all-read">
                <a href="javascript: void (0)" v-if="awardMessage.length" @click="readAward">全部已读</a>
              </div>
            </div>
            <v-scroll text="无消息" src="no_message" height="calc(100vh - 78px - var(--header-height)" class="scroll-view"
                      :http="awardMessageApi" @success="loadedAwardMessage">
              <template v-for="i in awardMessage">
                <div class="message-item">
                  <div class="user-group">
                    <img class="avatar" src="https://cdn.001ppt.cn/h5/assets/images/gold.png"/>
                    <div class="user-group-right">
                      <div class="flex-vertical">
                        <span class="user-name" :class="i.isRead === 'Y'&&'desc'">奖励消息</span>
                        <span class="time">{{formatDate(i.createTime)}}</span>
                      </div>
                      <span class="action" :class="i.isRead === 'Y'&&'desc'">{{i.awardContent}}</span>
                    </div>
                  </div>
                  <span class="btn fans" @click="$router.push('/record?tab=TASK')">看收益</span>
                </div>

              </template>
            </v-scroll>
          </div>

        </van-tab>
        <van-tab>
          <template #title>
            <span class="tab-title">收益消息</span>
          </template>

          <div class="tab-body">
            <div class="top-line">
              <div class="focus-tip" @click="$refs.gzhDialog.show()">想第一时间收到收益消息？</div>
              <div class="all-read">
                <a href="javascript: void (0)" v-if="profitMessage.length" @click="readProfit">全部已读</a>
              </div>
            </div>
            <v-scroll text="无消息" src="no_message" height="calc(100vh - 78px - var(--header-height)" class="scroll-view"
                      :http="profitMessageApi" @success="loadedProfitMessage">
              <template v-for="i in profitMessage">

                <div class="message-item">
                  <div class="user-group">
                    <div class="avatar" @click="goUserHome(i.userId)">
                      <avatar class="avatar" :src="i.planPhotoResourceUrl"></avatar>
                    </div>
                    <div class="user-group-right">
                      <div class="flex-vertical">
                        <span class="user-name" :class="i.isRead === 'Y'&&'desc'">{{i.planUsername}}</span>
                        <span class="time">{{formatDate(i.createTime)}}</span>
                      </div>
                      <span class="action" :class="i.isRead === 'Y'&&'desc'">{{i.remark}}</span>
                    </div>
                  </div>

                  <span class="btn fans" v-if="i.remark && i.remark.indexOf('优惠券')!==-1" @click="$router.push('/record?tab=RECHARGE')">看收益</span>
                  <span class="btn fans" v-else @click="$router.push('/record?tab=PROFIT')">看收益</span>
                </div>
              </template>
            </v-scroll>
          </div>


        </van-tab>
      </van-tabs>
    </div>
    <gzh-dialog :type="1" ref="gzhDialog"/>
  </page>
</template>

<script>
  //1：奖励提醒，2：收益提醒、3、动态消息，4：系统消息
  import {user} from '@/api'
  import vScroll from '@/components/scroll'
  import vMsg from '@/components/msg'
  import avatar from '@/components/avatar'
  import GzhDialog from '@/components/GzhDialog'
  import {nav} from "@/config/nav";

  export default {
    components: {vScroll, vMsg, avatar,GzhDialog},
    data() {
      return {
        active: 0,
        systemMessageApi: user.systemMsg,
        awardMessageApi: user.awardMsg,
        profitMessageApi: user.ProfitMsg,
        systemMessage: [],
        awardMessage: [],
        profitMessage: [],
      }
    },

    created() {

    },

    methods: {
      goUserHome(id) {
        this.$router.push('/otherPerson/' + id);
      },

      //全部消息
      loadedSystemMessage(data) {
        this.systemMessage = data;
      },


      //奖励消息
      loadedAwardMessage(data) {
        this.awardMessage = data;
      },

      //收益消息
      loadedProfitMessage(data) {
        this.profitMessage = data;
      },


      //去方案详情
      goPlanDetail(item) {
        if (item.profitType == 1) {
          nav.goPlanDetail(item.projectPlanId)
        } else {
          this.$router.push('/casePlanDetail/' + item.projectPlanId);
        }
      },

      async readAll() {
        const {code} = await user.readMessage({messageType: null});
        if (code != 1) return;
        this.systemMessage.forEach(item => {
          item.isRead = 'Y';
        });
        this.profitMessage.forEach(item => {
          item.isRead = 'Y';
        });
        this.awardMessage.forEach(item => {
          item.isRead = 'Y';
        });
      },

      //读奖励消息
      async readAward() {
        const {code} = await user.readMessage({messageType: 'USER_MESSAGE_TYPE_AWARD'});
        if (code != 1) return;
        this.awardMessage.forEach(item => {
          item.isRead = 'Y';
        });
      },

      //读收益消息
      async readProfit() {
        const {code} = await user.readMessage({messageType: 'USER_MESSAGE_TYPE_PROFIT'});
        if (code != 1) return;
        this.profitMessage.forEach(item => {
          item.isRead = 'Y';
        });
      },
    }
  }
</script>

<style lang="less" scoped>
  .message {
    .tab-title {
      font-weight: bold;
    }

    .top-line{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--grey-color);
      padding-top: 9px;
      padding-left: 27px;
      padding-right: 27px;
      font-size: 12px;
    }

    .tab-body {
      box-sizing: border-box;
      height: calc(100vh - 50px - var(--header-height));

      .all-read {
        box-sizing: border-box;
        text-align: right;

        a {
          color: var(--grey-color);
          font-size: 12px;
        }
      }

      .scroll-view {
        height: calc(100vh - 78px - var(--header-height));
        overflow-y: auto;
      }
    }

    .desc {
      color: var(--grey-color);
    }
  }
</style>
